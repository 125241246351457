@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: local("AvenirNextLTPro-Demi"), url("./fonts/avenir_ff/AvenirNextLTPro-Demi.otf") format("opentype");
}

@font-face {
  font-family: "AvenirNextLTPro-Medium";
  src: local("AvenirNextLTPro-Medium"), url("./fonts/avenir_ff/AvenirNextLTPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: local("AvenirNextLTPro-Regular"),url("./fonts/avenir_ff/AvenirNextLTPro-Regular.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "AvenirNextLTPro-Demi", "AvenirNextLTPro-Medium", "AvenirNextLTPro-Regular" ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
