/* Customize your main colors in :root variables */
:root {
  --main-background-color: #c9ced2;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color: #262626;
  --title-text-color: #415462;
  --main-button-background: #415462;
  --wallet-connect-button-background: #ef508c;
  --wallet-amount-background: #ef508d87;
  --social-media-color: #1b1b19;
}


body {
  background-color: var(--main-background-color);
  background-size: cover;
  font-family: "AvenirNextLTPro-Regular";
  font-size: 1.4em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-text-color);
  font-family: "AvenirNextLTPro-Medium";
}

@media screen and (max-width: 1000px) {
  p{
    font-size: 1em !important;
  }
}

p {
  color: var(--main-text-color);
  font-size: 1.4em;
  font-family: "AvenirNextLTPro-Regular";
}

h3 {
  font-weight: normal;
}

a {
  color: var(--title-text-color);
  font: bold;
  font-family: "AvenirNextLTPro-Demi";
}
